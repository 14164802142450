import React from "react";
import { Box, Container, Image, Heading, Button, Link } from "theme-ui";
import { keyframes } from "@emotion/core";
import logoImage from "assets/mate.png";
import bannerImage from "assets/maya.png";
import bannerIcon1 from "assets/banner-opensea.png";
import bannerIcon2 from "assets/banner-eth.png";
import bannerIcon3 from "assets/banner-icon-1-3.svg";
import bannerIcon4 from "assets/banner-icon-1-4.svg";
import bannerIcon5 from "assets/banner-icon-1-5.svg";
import bannerIcon6 from "assets/banner-icon-1-6.svg";
import bannerIcon7 from "assets/dot-pattern.svg";
import { appStyle } from "components/styles";

const Banner = () => {
  return (
    <Box as="section" sx={styles.banner} id="banner">
      <div style={styles.backgroundImage}></div>

      <Container sx={styles.container}>
        <Image
          sx={styles.bannerIcon1}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon1}
        />
        <Image
          sx={styles.bannerIcon2}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon2}
        />
        <Image
          sx={styles.bannerIcon3}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon3}
        />
        <Image
          sx={styles.bannerIcon4}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon4}
        />
        <Image
          sx={styles.bannerIcon5}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon5}
        />
        <Image
          sx={styles.bannerIcon6}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon6}
        />
        <Image
          sx={styles.bannerIcon7}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon7}
        />
        <Box sx={styles.bannerContainer}>
          <Box sx={styles.mateContainer}>
            <Image sx={styles.logo} src={logoImage} alt="Crypto Mate Banner" />
          </Box>
          <Box sx={styles.titleContainer}>
            <Heading as="h1" sx={styles.descriptiveTitle}>
              #MyCryptoMate Colección NFT ultra realista
              <div>
                <Link
                  target="_blank"
                  sx={appStyle.btnWhite}
                  href="https://opensea.io/MyCryptoMate"
                >
                  Ver Materia
                </Link>
              </div>
            </Heading>
          </Box>
        </Box>
        <Heading as="h2" data-aos="fade-in-left">
          Cansado de que el Té no sea auténtico Mate
        </Heading>
        <Heading as="h2" data-aos="fade-in-right">
          De los NFTs copiados de internet por el pibe de tu primo
        </Heading>

        <Image src={bannerImage} sx={styles.bannerImage} alt="banner moc" />
      </Container>
    </Box>
  );
};

export default Banner;

const bannerAnim1 = keyframes`
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
`;

const bannerAnim2 = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const bannerAnim3 = keyframes`
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    }
`;

const styles = {
  button: {
    fontSize: "15px",
    fw: "700",
    height: "48px",
    borderRadius: "3px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    py: "0",
    backgroundColor: "black",
    color: "#fff",
  },
  banner: {
    overflow: "hidden",
    backgroundColor: "#F9FBFD",
    textAlign: "center",
    h2: {
      fontSize: ["28px", null, null, "32px", "38px", "48px", "64px"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      maxWidth: ["100%", null, null, "55%", "500px", "640px", "851px"],
      mx: "auto",
      mt: "30px",
      mb: ["40px", null, null, "65px"],
    },
    h1: {
      fontSize: ["28px", null, null, "32px", "38px", "48px", "64px"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      maxWidth: ["100%", null, null, "55%", "500px", "640px", "851px"],
      mx: "auto",
      mt: "30px",
      mb: ["40px", null, null, "65px"],
    },
  },

  logo: {
    width: ["12em", "12em", "18em", "18em", "18em"],
    height: ["20em", "20em", "32em", "32em", "32em"],
    marginTop: "10vh"
  },
  bannerContainer: {
    display: "flex",
    flexDirection: ["column", "column", "column", "column", "row"],
    textAlign: ["center", null, null, "left"],
    height: "100vh",
    alignItems: 'center'
  },
  mateContainer: {
    width: ["16em", "16em", "20em", "20em", "22em"],
    height: ["32em", "32em", "42em", "42em", "42em"],
  },
  titleContainer: {
    width: ["100vw", "100vw", "100vw", "100vw", "60vw"],
    paddingTop: [0, 0, 0, 0, "0em"],
    display: "block",
    mx: "auto",
    position: "relative",
  },
  descriptiveTitle: {
    color: "white !important",
    paddingTop: [0, 0, 0, 0,0],
    textAlign: "center",
  },
  backgroundImage: {
    display: "block",
    height: "100%",
    width: "100%",
    left: 0,
    padding: 0,
    margin: 0,
    position: "absolute",
    background: "linear-gradient(135deg,#00dfbf,#007cff)",
  },
  bannerImage: {
    display: "block",
    mx: "auto",
    position: "relative",
    width: ["16em", "16em", "20em", "22em", "22em"],
    height: ["32em", "32em", "42em", "42em", "42em"],
  },
  container: {
    position: "relative",
    ".bannerIcon": {
      position: "absolute",
      display: ["none", null, null, null, "block"],
    },
  },
  bannerIcon1: {
    top: "10%",
    left: "10%",
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon2: {
    top: "10%",
    right: "10%",
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon3: {
    bottom: "40px",
    right: "-120px",
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon4: {
    bottom: "130px",
    left: "-120px",
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon5: {
    bottom: "50%",
    left: "15%",
  },
  bannerIcon6: {
    bottom: "-65px",
    left: "0px",
    animation: `${bannerAnim3} 9s linear infinite`,
  },
  bannerIcon7: {
    bottom: "30%",
    right: "0%",
  },
};
