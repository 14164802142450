import Layout from "components/layout";
import SEO from "components/seo";
import { StickyProvider } from "contexts/app/app.provider";
import "rc-drawer/assets/index.css";
import React from "react";
import AboutUs from "sections/aboutUs";
import Banner from "sections/banner";
import FAQ from "sections/faq";
import Subscribe from "sections/subscribe";
import "swiper/swiper-bundle.min.css";
import "typeface-dm-sans";
import { SocialMedia } from "../components/socialMedia";
import DesignProcess from "../sections/designProcess";
import NFTProducts from "../sections/nftProducts";

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout style={{ height: "100%", overflowX: "hidden" }}>
        <SEO
          title="Yerba Mate NFTs"
          description="Opensea top Nft de Mates. La mejor colección Yerba Mate de Argentina en la red Matic. Descubre los 10 secretos de My Crypto Mate"
        />
        <Banner />
        <DesignProcess />
        <NFTProducts />
        <Subscribe />
        <SocialMedia />
        <AboutUs />
        <FAQ />
      </Layout>
    </StickyProvider>
  );
}
