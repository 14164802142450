import React from "react";
import { Heading, Image , Box} from "theme-ui";
import "./styles.css";
import PokemonMate from "assets/pokemon-mate.jpeg";

export const SocialMedia = () => {
  return (
    <Box className="social-container" sx={styles}>
      <Heading as="h3">Síguenos en nuestras redes sociales</Heading>
      <Image src={PokemonMate}  alt="Insagram Mate Post" />
      
      <ul class="social-icons">
        <li>
          <a href="https://www.instagram.com/mycryptomate/" target="=_blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/mycryptomate" target="=_blank">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@bobokenofficial">
            <i class="fa-brands fa-tiktok" target="=_blank"></i>
          </a>
        </li>
      </ul>
    </Box>
  );
};

const styles = {
  height: ['90vh', '90vh', '90vh', '90vh', '100%'],
  display: 'block',
  width: '400px',
  margin:  'auto',
  textAlign: 'center'
  
};
