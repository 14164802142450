import arrowAngle from "assets/arrow-angle.svg";
import React from "react";
import { Box, Heading, Image, Text, Link } from "theme-ui";
import { appStyle } from "components/styles";

const NftCard = ({ image, text, heading, path, price, type }) => {
  const order = {
    right: {
      imageOrder: [0, null, null, null, 1],
      textOrder: [1, null, null, null, 0],
    },
    left: {
      imageOrder: [0, null, null, null, 0],
      textOrder: [1, null, null, null, 1],
    },
  };

  return (
    <Box sx={styles.NftCard}>
      <Box
        data-aos="fade-zoom-in-up"
        data-aos-duration={2000}
        sx={{ ...styles.imageContainer, order: order[type].imageOrder }}
      >
        <Image src={image} alt={heading} sx={styles.image} />
      </Box>
      <Box sx={{ ...styles.content, ...order[type].textOrder }}>
        <Heading data-aos="fade-up" data-aos-duration={4000} as="h3">
          {heading}
        </Heading>
        <Text data-aos="fade-up" data-aos-duration={6000} as="p">
          {text}
        </Text>
        <Link   target="_blank" sx={{...styles.link, ...appStyle.btnBlack}} href={path}>
          Compra <Image src={arrowAngle} alt="angle icon" />
        </Link>
      </Box>
    </Box>
  );
};

export default NftCard;

const styles = {
  NftCard: {
    display: "flex",
    flexDirection: ["column", "column", "column", "column", "row"],
    textAlign: ["center", null, null, "left"],
  },
  imageContainer: {
    flexShrink: 0,
  },
  image: {
    width: ["16em", "16em", "20em", "20em", "22em"],
    height: ["32em", "32em", "42em", "42em", "42em"],
  },
  content: {
    ml: ["0", null, null, "200px"],
    mt: ["20px", null, null, "10vh"],
    h3: {
      color: "#0F2137",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: 1,
    },
    p: {
      fontSize: "16px",
      lineHeight: 1.87,
      color: "#343D48",
      mt: "20px",
      mb: "20px",
    },
  },
  link: {
    color: "#3183FF",
    fontSize: "16px",
    fontWeight: 500,
    display: "inline-block",
    img: {
      ml: "5px",
    },
  },
};
