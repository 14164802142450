import React from "react";
import { Box, Heading, Text, Container } from "theme-ui";

const AboutUs = () => {
  return (
    <Box as="section" sx={styles.callToAction}>
      <Container>
        <Box sx={styles.content}>
          <Text as="span">Atención</Text>
          <Heading>MyCryptoMate es una colección limitada</Heading>
          <Heading> Diez Mates únicos.</Heading>
          <Heading> Sin copias, una bebida irrepetible.</Heading>
          <Heading> Cada Mate representa un sabor del mundo digital... </Heading>

        </Box>
      </Container>
    </Box>
  );
};
export default AboutUs;

const styles = {
  callToAction: {
    backgroundColor: '#020718',
    mt: ["-90px", null, null, null, "0"],
    py: ["50px", null, null, null, "110px", null, "140px"],
  },

  content: {
    flex: ["0 0 100%", null, null, null, "0 0 38.5%"],
    textAlign: ["center", null, null, null, "left"],
    pt: ["80px", null, null, null, "0"],
    maxWidth: ["100%", null, null, "80%", "100%"],
    mx: ["auto", null, null, null, "0"],
    mb: ["30px", null, null, null, "0"],
    span: {
      fontSize: "18px",
      fontWeight: 700,
      color: "primary",
      display: "block",
      lineHeight: 1,
    },
    h2: {
      color: "white",
      fontWeight: 700,
      fontSize: ["23px", null, null, null, "30px", "36px", "44px"],
      maxWidth: ["100%", null, null, null, null, "90%", "100%"],
      lineHeight: 1.36,
      letterSpacing: "-1.5px",
      mt: "20px",
      mb: "30px",
    },
    p: {
      color: "#02073E",
      fontSize: ["16px", null, null, "18px"],
      lineHeight: ["2", null, null, 2.33],
      mb: "30px",
    },
  },
};
