export const appStyle = {
  btnWhite: {
    display: "inline-block",
    height: "56px",
    marginTop: "10px",
    marginRight: "0",
    marginLeft: "0",
    paddingRight: "32px",
    paddingBottom: "15px",
    paddingLeft: "32px",
    backgroundColor: "#fff",
    color: "#161a25",
    padding: "15px 32px",
    borderRadius: "28px",
    fontSize: "17px",
    lineHeight: "28px",
    "&:hover": { boxShadow: "0 4px 12px 0 rgb(22 26 37 / 25%)" },
  },
  btnBlack: {
    display: "inline-block",
    height: "56px",
    marginTop: "10px",
    marginRight: "0",
    marginLeft: "0",
    paddingRight: "32px",
    paddingBottom: "15px",
    paddingLeft: "32px",
    color: "#fff",
    backgroundColor: "#161a25",
    padding: "15px 32px",
    borderRadius: "28px",
    fontSize: "17px",
    lineHeight: "28px",
    "&:hover": { boxShadow: "0 4px 12px 0 rgb(22 26 37 / 25%)" },

  },
};
