import { keyframes } from "@emotion/core";
import bannerImage from "assets/maya.png";
import moldeImage from "assets/molde.png";
import dibujoImage from "assets/dibujo.png";
import acabadoImage from "assets/acabado.png";
import React, { useEffect, useState } from "react";
import { Box, Container, Heading, Image } from "theme-ui";
import { useOpacityHook } from "../components/image-scroll/opacityHook";
import { setFadeIn, setFadeOut } from "../components/image-scroll/effects";

const defaultWidth = 0//
const DesignProcess = () => {
  const headerRef1 = React.useRef();
  const headerRef2 = React.useRef();
  const headerRef3 = React.useRef();
  const headerRef4 = React.useRef();
  const headerRef5 = React.useRef();
  const headerRef6 = React.useRef();

  const imageRef1 = React.useRef();
  const imageRef2 = React.useRef();
  const imageRef3 = React.useRef();

  const positionedImageRef1 = React.useRef();
  const positionedImageRef2 = React.useRef();
  const positionedImageRef3 = React.useRef();
  const [width, setWidth] = useState(0);
  
  if (typeof window !== `undefined` && width === 0) {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    
      const handleWindowSizeChange = ()=> {
        setWidth(window.innerWidth);
      }
    window.addEventListener("resize", handleWindowSizeChange);

    var windowScrollY = window.scrollY;
    const header1 = headerRef1.current;
    const header2 = headerRef2.current;
    const header3 = headerRef3.current;
    const header4 = headerRef4.current;
    const header5 = headerRef5.current;
    const header6 = headerRef6.current;
    const isMobile = width <= 768;

    window.addEventListener("scroll", function () {
      windowScrollY = window.scrollY;
      const headerList = [header1, header2, header3, header4, header5, header6];
      const imageList = [
        { image: imageRef1, positioned: positionedImageRef1 },
        { image: imageRef2, positioned: positionedImageRef2 },
        { image: imageRef3, positioned: positionedImageRef3 },
      ];
      headerList.map((header) => {
        const { x, y, top, height } = header.getBoundingClientRect();
        if (y > -300 && y < 300) {
          setFadeIn(header);
        } else {
          setFadeOut(header);
        }
      });

      imageList.map(({ image, positioned }, index) => {
        const imageCurrent = image.current;
        const positionedCurrent = positioned.current;

        const { x, y, top, height } = positionedCurrent.getBoundingClientRect();
        const {
          y: lastY,
        } = positionedImageRef3.current.getBoundingClientRect();
        const {
          y: firstY,
        } = positionedImageRef1.current.getBoundingClientRect();

        if (isMobile) {
          if (index === 0){
            console.log({ x }, { y }, { top }, { height });
// Desplazamiento de 300 a 0 es cuando va girar de derecha a izq

          }

          if (y > -200 && y < 500) {
            const translateX = `translateX(${y / 3}%)`;
            imageCurrent.style.transform = translateX;
            setFadeIn(imageCurrent);          }
          else {
            setFadeOut(imageCurrent);
          }
        } else {
          if (y > -200 && y < 500) {
            const translateX = `translateX(${index * 30}em)`;
            imageCurrent.style.transform = translateX;
            setFadeIn(imageCurrent);
          }
          if (lastY < 200 || firstY > 400) {
            setFadeOut(imageCurrent);
            const translateX = `translateX(${index * -30}em)`;
            imageCurrent.style.transform = translateX;
          }
          if (index === 0) {
            console.log({ lastY }, { firstY });
            // console.log({ x }, { y }, { top }, { height });
          }
        }
      });
    });
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <Box as="section" id="design" sx={styles.banner}>
      <Container sx={styles.container}>
        <Heading ref={headerRef1} as="h2">
          Diseño único del Mate
        </Heading>
        <Heading ref={headerRef2} as="h2">
          Hemos cuidado cada detalle
        </Heading>
        <Heading ref={headerRef3} as="h2">
          Elige el mate que más se adapte a ti. Pero...
        </Heading>
        <Heading ref={headerRef4} as="h2">
          Corre!
        </Heading>
        <Heading ref={headerRef5} as="h2">
          Matías va a cambiar la carta y solo le quedan los TOPs 10
        </Heading>
        <Heading ref={headerRef6} as="h2">
          Ha unido el Mate con el mundo digital. Te mostramos algunas de
          nuestras simbiosis:
        </Heading>

        <Box ref={positionedImageRef1} sx={styles.positionedImage} />
        <Box ref={positionedImageRef2} sx={styles.positionedImage} />
        <Box ref={positionedImageRef3} sx={styles.positionedImage} />
        <Box sx={styles.imageContainer}>
          <Image
            ref={imageRef1}
            src={dibujoImage}
            sx={styles.bannerImage}
            alt="Dibujo del Mate"
          />
          <Image
            ref={imageRef2}
            src={moldeImage}
            sx={styles.bannerImage}
            alt="Model del Mate"
          />
          <Image
            ref={imageRef3}
            src={acabadoImage}
            sx={styles.bannerImage}
            alt="Acabado del Mate"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default DesignProcess;

const bannerAnim1 = keyframes`
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
`;

const bannerAnim2 = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const bannerAnim3 = keyframes`
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    }
`;

const styles = {
  banner: {
    overflow: "hidden",
    backgroundColor: "#F9FBFD",
    textAlign: "center",
    pt: ["110px", null, null, null, "130px"],
    h2: {
      fontSize: ["28px", null, null, "32px", "38px", "48px", "64px"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      maxWidth: ["100%", null, null, "55%", "500px", "640px", "851px"],
      mx: "auto",
      mt: "30px",
      mb: ["40px", null, null, "65px"],
    },
  },
  logo: {
    display: "block",
    borderRadius: "50%",
    mx: "auto",
    boxShadow: "0px 15px 35px rgba(65, 104, 139, 0.12)",
  },
  positionedImage: {
    display: "block",
    mx: "auto",
    position: "relative",
    height: ["60vh", null, null, null, "20em"],
    bottom: 0,
    maxWidth: ["100%", null, null, "80%", null, "100%"],
  },
  imageContainer: {
    display: "flex",
    flexDirection: ["column", "column", "column", "column", "row"],
    textAlign: ["center", null, null, "left"],
    height: "100vh",
  },
  bannerImage: {
    display: "block",
    mx: "auto",
    position: "fixed",
    height: ["18em", null, null, null, "35em"],
    bottom: 0,
    visibility: "hidden",
    width: ["10em", null, "20em", "20em", "20em"],
    maxWidth: ["20em", null, null, "20em", null, "20em"],
  },
  container: {
    position: "relative",
    ".bannerIcon": {
      position: "absolute",
      display: ["none", null, null, null, "block"],
    },
    height: ["130em", '130em', '145em', '145em', '145em'],
  },
  bannerIcon1: {
    top: "10%",
    left: "10%",
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon2: {
    top: "10%",
    right: "10%",
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon3: {
    bottom: "40px",
    right: "-120px",
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon4: {
    bottom: "130px",
    left: "-120px",
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon5: {
    bottom: "50%",
    left: "15%",
  },
  bannerIcon6: {
    bottom: "-65px",
    left: "0px",
    animation: `${bannerAnim3} 9s linear infinite`,
  },
  bannerIcon7: {
    bottom: "30%",
    right: "0%",
  },
};
