import React from "react";
import { Box, Container, Grid } from "theme-ui";
import BlockTitle from "components/block-title";
import serviceImage1 from "assets/btc.png";
import serviceImage2 from "assets/eth.png";
import serviceImage3 from "assets/bnb.png";
import NftCard from "components/cards/nft-card";

const SERVICES_DATA = [
  {
    image: serviceImage1,
    text:
      "Con un marketcap superior a $726.775.402.359 USD y con alrededor de 18.975.700 monedas en circulación. Descrito en el famoso whitepaper en 2008 por Satoshi Nakamoto (alias). Considerada la impulsora de la nueva orden económica.Disfruta el sabor de la revolución con aroma a innovación tecnológica y peculiar diseño premium para nuestra numero #1",
    heading: "Mate Bitcoin #1",
    price: "200eur",
    path: "#",
  },
  {
    image: serviceImage2,
    text:
      "El legado de Vitalik Buterin. Pionero en el concepto de contrato inteligente en la blockchain.  Con un marketcap de $311.752.536.095 USD. Actualmente existen 119.846.511 ETH coins en circulación. Considerada la simbiosis entre un notario y mate del poder.",
    heading: "Mate Ethereum #2",
    price: "200eur",
    path: "#",
  },
  {
    image: serviceImage3,
    text:
      "Nuestra tercera ganadora es Binance, sin contar StableCoins. Tiene un marketcap $61.854.187.504 USD y actualmente con  165.116.761 BNB monedas en circulación. Changpeng Zhao como cabeza fundadora. Binance es un ecosistema descentralizado y la compañia líder en el sector. Se considera que el poseedor de este Mate superara en riqueza al hombre más rico de Babilonia. La particular simbiosis entre los valores del negocio y la humildad del mate",
    heading: "Mate Binance #3",
    price: "200eur",
    path: "#",
  },
];
const NFTProducts = () => {
  return (
    <Box as="section" id="top10" sx={styles.services}>
      <Container>
        <BlockTitle
          title="TOP 10 Hot Crypto Mates"
          text="Los Mates más calientes  "
        />
        <Grid sx={styles.grid} >
          {SERVICES_DATA.map(({ image, text, heading, path, price }, index) => (
            <NftCard
              image={image}
              text={text}
              heading={heading}
              path={path}
              key={index}
              price={price}
              type={index%2 ? 'right' : 'left'}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default NFTProducts;

const styles = {
  services: {
    pt: ["80px", null, null, null, "80px", null, "100px"],
  },
  grid: {
    gridGap: "10px",
  },
};
